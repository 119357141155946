import asaColor from '@asa/asasqt-microfrontend-components/dist/theme/foundations/asaColors';

export const colors = {
  brand: asaColor.red,
  secondary: asaColor.blue,
  gray: asaColor.gray,
  success: asaColor.green,
  error: asaColor.darkRed,
  warning: asaColor.orange,
  transparent: asaColor.transparent,
  tertiary: asaColor.purple,
};
