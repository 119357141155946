import { borderRadius } from '@asa/asasqt-microfrontend-components/dist/theme/foundations/borderRadius';
import { layout } from '@asa/asasqt-microfrontend-components/dist/theme/foundations/layout';
import { shadows } from '@asa/asasqt-microfrontend-components/dist/theme/foundations/shadows';
import { spacing } from '@asa/asasqt-microfrontend-components/dist/theme/foundations/spacing';
import { typography } from '@asa/asasqt-microfrontend-components/dist/theme/foundations/typography';
import { zIndices } from '@asa/asasqt-microfrontend-components/dist/theme/foundations/z-index';

import { colors } from './colors';

const foundations = {
  colors,
  ...typography,
  shadows,
  space: spacing,
  layout,
  zIndices,
  borderRadius,
};

export default foundations;
