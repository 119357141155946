import { useContext } from 'react';

import { LanguageContext } from '@/core/common/language';

import { TranslatedURLRoute, URLRoutes } from './urlRoutes.types';

export const useURLRoutesLang = () => {
  const { tr } = useContext(LanguageContext);

  return {
    home: tr('common.label.home'),
    customerVehicles: tr('common.label.customerVehicles'),
    quotation: tr('common.label.quotation'),
    dashboard: tr('common.label.dashboard'),
    settings: tr('common.label.settings.title'),
    userSettings: tr('common.label.settings.user'),
    accountSettings: tr('common.label.settings.account'),
    countrySettings: tr('common.label.settings.country'),
    customPartsAndFluids: tr('common.label.customPartsAndFluids'),
    customPartsAndFluidsNew: tr('common.label.action.addNew'),
    customPartsAndFluidsEdit: tr('common.label.details'),
    fluidsAssignment: tr('common.label.fluids.assignment'),
    assignFluids: tr('common.label.fluids.assign'),
    assignFluidsEdit: tr('common.label.details'),
    customPrices: tr('common.label.customPrices'),
    customPriceForm: tr('common.label.details'),
    customOperations: tr('common.label.customOp'),
    customOperationsNew: tr('common.label.action.addNew'),
    customOperationsEdit: tr('common.label.details'),
    serviceApp: tr('common.label.serviceApp'),
    serviceAppCreate: tr('common.label.serviceApp'),
    serviceAppEdit: tr('common.label.serviceApp'),
    vehicleHealthCheck: tr('common.label.vhc.title'),
    vhcWizard: tr('common.label.vhc.wizard'),
    VHC: tr('common.label.vhc.titleAbr'),
    VHCList: tr('common.label.vhc.titleAbr'),
    menuList: tr('common.label.menuSelection'),
    quotationWizard: tr('common.label.wizard'),
    notifications: tr('common.label.notifications'),
    unauthorized: tr('common.label.unauthorized'),
    noInitialSetup: tr('common.label.unauthorized'),
    back: tr('common.label.action.back'),
  };
};

export const useMappedURLRoute = (): TranslatedURLRoute => {
  const T = useURLRoutesLang();

  const {
    home,
    settings,
    userSettings,
    accountSettings,
    countrySettings,
    customPartsAndFluids,
    customPartsAndFluidsNew,
    customPartsAndFluidsEdit,
    fluidsAssignment,
    assignFluids,
    assignFluidsEdit,
    customPrices,
    customPriceForm,
    customOperations,
    customOperationsNew,
    customOperationsEdit,
    serviceApp,
    serviceAppCreate,
    serviceAppEdit,
    vehicleHealthCheck,
    customerVehicles,
    vhcWizard,
    VHCList,
    menuList,
    quotationWizard,
    notifications,
    unauthorized,
    noInitialSetup,
    back,
  } = T;

  return {
    ...URLRoutes,
    [URLRoutes.dashboard]: home,
    [URLRoutes.settings]: settings,
    [URLRoutes.userSettings]: userSettings,
    [URLRoutes.accountSettings]: accountSettings,
    [URLRoutes.countrySettings]: countrySettings,
    [URLRoutes.customPartsAndFluids]: customPartsAndFluids,
    [URLRoutes.customPartsAndFluidsNew]: customPartsAndFluidsNew,
    [URLRoutes.customPartsAndFluidsEdit]: customPartsAndFluidsEdit,
    [URLRoutes.fluidsAssignment]: fluidsAssignment,
    [URLRoutes.assignFluids]: assignFluids,
    [URLRoutes.assignFluidsEdit]: assignFluidsEdit,
    [URLRoutes.customPrices]: customPrices,
    [URLRoutes.customPriceForm]: customPriceForm,
    [URLRoutes.customOperations]: customOperations,
    [URLRoutes.customOperationsNew]: customOperationsNew,
    [URLRoutes.customOperationsEdit]: customOperationsEdit,
    [URLRoutes.serviceApp]: serviceApp,
    [URLRoutes.serviceAppCreate]: serviceAppCreate,
    [URLRoutes.serviceAppEdit]: serviceAppEdit,
    [URLRoutes.vehicleHealthCheck]: vehicleHealthCheck,
    [URLRoutes.customerList]: customerVehicles,
    [URLRoutes.vhcWizard]: vhcWizard,
    [URLRoutes.vhcList]: VHCList,
    [URLRoutes.menuList]: menuList,
    [URLRoutes.quotationWizard]: quotationWizard,
    [URLRoutes.notifications]: notifications,
    [URLRoutes.unauthorized]: unauthorized,
    [URLRoutes.noInitialSetup]: noInitialSetup,
    [URLRoutes.back]: back,
  };
};
