import { Dispatch, SetStateAction } from 'react';

import { UserSettings } from '@asa/asasqt-microfrontend-components/dist/common/types';
import {
  ConnectionTypeValues,
  PriceSettingsValues,
} from '@asa/asasqt-microfrontend-components/dist/common/types/shared/auth';

import { StockAvailabilityResponse } from '@/core/common/api/serviceHub';

export type ActionButtonsAtomProps = {
  onClickSave: () => void;
  onClickCancel: () => void;
  onClickEdit: () => void;
  isReadonly: boolean;
};

export type ActionButtonContainerProps = {
  handleSave: () => void;
  handleCancel: () => void;
  handleEdit: () => void;
  isReadonly: boolean;
};

export type HeaderSectionMoleculeProps = ActionButtonContainerProps & {};

export type BottomSectionMoleculeProps = ActionButtonContainerProps & {};

export type UserData = {
  language: string;
  username: string;
};

export type UserSettingsForm = Omit<
  UserSettings,
  'id' | 'defaultLanguage' | 'version'
>;

export type DmsConnectionForm = Omit<
  UserSettingsForm,
  'prices' | 'connectionType'
> & {
  prices?: PriceSettingsValues;
  connectionType?: ConnectionTypeValues;
  connectionStatus?: DmsConnectionStatus;
};

export interface StockAvailabilityForm {
  customerNumber?: string;
  partNumber?: string;
  requestedUnits?: number;
  connectionStatus?: DmsConnectionStatus;
}

export type EditableUserSettingsOrganismProps = {
  userData: UserData;
  setUserData: Dispatch<SetStateAction<UserData>>;
  isChanged?: () => void;
};

export type ReadonlyUserSettingsOrganismProps = {
  userData: UserData;
  userSettingsApiData?: UserSettings;
  connectionStatus?: DmsConnectionStatus;
};

export enum DmsConnectionStatus {
  NotConnected = 'NotConnected',
  Loading = 'Loading',
  Connected = 'Connected',
  Failed = 'Failed',
}

export type DmsConnectionStatusAtomProps = {
  status?: DmsConnectionStatus;
  onClick?: () => void;
  isVerifyPart?: boolean;
  connectionTimeStamp?: string;
  debugInfo?: string;
  isDisabled?: boolean;
};

export type DmsTestingStockAtomProps = {
  stockAvailability: StockAvailabilityResponse;
  onClickVerifyPart?: () => void;
};
