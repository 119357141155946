import { atom } from 'recoil';

import { Interaction } from '@asa/asasqt-microfrontend-components/dist/common/state/utils/types';

import {
  initialAppointmentTypesForm,
  initialEmailNotifications,
  initialSchedulingForm,
} from '../utils/consts';
import { CustomAppointmentInfo, ServiceAppSettings } from '../utils/types';

export const serviceAppSettingsAtom = atom<ServiceAppSettings | null>({
  key: 'serviceAppSettingsAtom',
  default: null as ServiceAppSettings | null,
});

export const serviceAppSettingsInteractionModeAtom = atom({
  key: 'serviceAppSettingsInteractionModeAtom',
  default: Interaction.None,
});

export const serviceAppSettingsReadonly = atom({
  key: 'serviceAppSettingsReadonly',
  default: true,
});

export const appointmentRequestsToggleAtom = atom<boolean>({
  key: 'appointmentRequestsToggle',
  default: false,
});

export const emailNotificationsAtom = atom({
  key: 'emailNotifications',
  default: initialEmailNotifications,
});

export const appointmentTypesFormAtom = atom({
  key: 'appointmentTypesFormAtom',
  default: initialAppointmentTypesForm,
});

export const invalidCustomAppointmentsAtom = atom({
  key: 'invalidCustomAppointmentsAtom',
  default: [] as unknown as CustomAppointmentInfo[] | null,
});

export const schedulingFormAtom = atom({
  key: 'schedulingFormAtom',
  default: initialSchedulingForm,
});
