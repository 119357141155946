export type TranslatedURLRoute = {
  [key in URLRoutes]: string;
};

export enum URLRoutes {
  dashboard = 'dashboard',
  settings = 'settings',
  userSettings = 'userSettings',
  accountSettings = 'accountSettings',
  countrySettings = 'countrySettings',
  customPartsAndFluids = 'customPartsAndFluids',
  customPartsAndFluidsNew = 'customPartsAndFluidsNew',
  customPartsAndFluidsEdit = 'customPartsAndFluidsEdit',
  fluidsAssignment = 'fluidsAssignment',
  assignFluids = 'assignFluids',
  assignFluidsEdit = 'assignFluidsEdit',
  customPrices = 'customPrices',
  customPriceForm = 'customPriceForm',
  customOperations = 'customOperations',
  serviceApp = 'serviceApp',
  serviceAppCreate = 'serviceApp-create',
  serviceAppEdit = 'serviceApp-edit',
  customOperationsNew = 'new',
  customOperationsEdit = 'customOperationsEdit',
  initialSetup = 'initialSetup',
  auth = 'auth',
  legalDocuments = 'legalDocuments',
  vhcList = 'vhc-list',
  vehicleHealthCheck = 'vhc-create',
  vhcWizard = 'edit',
  customerList = 'customer-list',
  menuList = 'menu-list',
  quotationWizard = 'wizard',
  notifications = 'notifications',
  unauthorized = 'unauthorized',
  noInitialSetup = 'noInitialSetup',
  redirect = 'redirect',
  back = 'back',
  landingPages = 'landingPages',
}
