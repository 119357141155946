import { useContext, useEffect, useState } from 'react';

import { MenuItem } from '@chakra-ui/react';

import useAsyncData from '@asa/asasqt-microfrontend-components/dist/hooks/useAsync';

import { loginToMyAsaInfo } from '@/core/settings/userSettings/core';

import { useAxiosIntercept } from '../../axios/axios.context';
import { URLRoutes } from '../../helpers';
import { LanguageContext } from '../../language';

interface MenuLinkProps {
  redirectPage: string;
  landingPage: string | undefined;
  translation: string;
}

export const MenuLink = ({
  redirectPage,
  landingPage,
  translation,
}: MenuLinkProps) => {
  const { tr } = useContext(LanguageContext);
  const axiosIntercept = useAxiosIntercept();
  const [focused, setFocus] = useState<boolean>(true);

  const { loadData: redirect, data: redirectData } = useAsyncData({
    fetchFn: loginToMyAsaInfo,
    paramsOfFetch: {
      axiosIntercept,
      data: {
        targetIliasApplication: redirectPage,
        landingPage: landingPage,
      },
    },
  });

  useEffect(() => {
    if (redirectData) {
      const tab = window.open(`/${URLRoutes.redirect}/`, '_blank');
      if (tab) {
        const handleMessage = (event: MessageEvent) => {
          if (event.data === 'READY_FOR_DATA') {
            tab.postMessage(redirectData, '*');
            window.removeEventListener('message', handleMessage);
          }
        };

        window.addEventListener('message', handleMessage);
      }
    }
  }, [redirectData]);

  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);

  useEffect(() => {
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);
    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  const handleClickOnLink = async () => {
    redirect();
    handleBlur();
  };

  return <MenuItem onClick={handleClickOnLink}>{tr(translation)}</MenuItem>;
};
