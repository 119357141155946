import { atom } from 'recoil';

import { RecoilApiData } from '@/core/common/recoil';
import {
  DmsConnectionForm,
  DmsConnectionStatus,
} from '@/core/settings/userSettings/utils';

const initialDmsConnectionForm: DmsConnectionForm = {
  connectionStatus: DmsConnectionStatus.NotConnected,
  useDmsPrice: false,
  showStockAvailability: true,
  transferDescriptionsInCapital: true,
};

export const dmsConnectionFormAtom = atom({
  key: 'dmsConnectionForm',
  default: initialDmsConnectionForm,
});

export const stockAvailabilityFormAtom = atom({
  key: 'stockAvailabilityForm',
  default: {},
});

export const dealerNumberFormAtom = atom<string>({
  key: 'dealerNumberForm',
  default: '',
});

export const dealerNumberApiDataAtom = atom<RecoilApiData>({
  key: 'dealerNumberApiData',
  default: {
    isLoading: false,
    data: '',
  },
});
