import React, { Dispatch, FC, SetStateAction } from 'react';

import { useAuthentication } from '@/core/common/hooks/useAuthentication';
import { useAuthorization } from '@/core/common/hooks/useAuthorization';

import { useUserServices } from '../../common/hooks/useUserServices';

export declare type AuthContextType = {
  isAuthenticated: boolean;
  setIsAuthenticated?: Dispatch<SetStateAction<boolean | undefined>>;
  userServices: TODO; //sync from @asa-components
  loadAuthorizationData: TODO;
};

export const AuthContext = React.createContext<AuthContextType>(null as TODO);
export const AuthProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { isAuthenticated } = useAuthentication();
  const { userServices } = useUserServices();
  const { loadAuthorizationData } = useAuthorization();

  return (
    <>
      <AuthContext.Provider
        value={{
          isAuthenticated,
          userServices,
          loadAuthorizationData,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};
