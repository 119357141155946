import { UserRole } from '@asa/asasqt-microfrontend-components/dist/common/types/shared/api/userServices';

enum LicenseList {
  qsm00 = 'product-key-asasqt-m00',
  qsm50 = 'product-key-asasqt-m50',
  qsm60 = 'product-key-asasqt-m60',
  qsm70 = 'product-key-asasqt-m70',
  qsm80 = 'product-key-asasqt-m80',
}

enum MarketList {
  qsm00 = 'asasqt-quotation-service-m00',
  qsm50 = 'asasqt-quotation-service-m50',
  qsm60 = 'asasqt-quotation-service-m60',
  qsm70 = 'asasqt-quotation-service-m70',
  qsm80 = 'asasqt-quotation-service-m80',
  avm50 = 'asasqt-addon-vspdmx-m50',
  avm60 = 'asasqt-addon-vspdmx-m60',
  avm70 = 'asasqt-addon-vspdmx-m70',
}

export enum Market {
  qsm50 = MarketList.qsm50,
  qsm60 = MarketList.qsm60,
  qsm70 = MarketList.qsm70,
  qsm80 = MarketList.qsm80,
  avm50 = MarketList.avm50,
  avm60 = MarketList.avm60,
  avm70 = MarketList.avm70,
}

export enum License {
  qsm00 = LicenseList.qsm00,
  qsm50 = LicenseList.qsm50,
  qsm60 = LicenseList.qsm60,
  qsm70 = LicenseList.qsm70,
  qsm80 = LicenseList.qsm80,
}

export type UserServices = {
  roles: UserRole[];
  markets: Market[];
};

export const ALL_USER_24_SERVICES: Array<string | Market> = [
  ...Object.values(UserRole),
  ...Object.values(Market),
];
