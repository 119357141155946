import { AxiosResponse } from 'axios';

import {
  AxiosRequest,
  ErrorResponse,
  handleError,
} from '@asa/asasqt-microfrontend-components/dist/common/api/axios';
import {
  PORTAL_ROUTES,
  PORTAL_ROUTES_NAMES,
} from '@asa/asasqt-microfrontend-components/dist/common/api/myAsaSqt/portal';
import { UserSettings } from '@asa/asasqt-microfrontend-components/dist/common/types';
import { UserInfoResponse } from '@asa/asasqt-microfrontend-components/dist/common/types/shared/auth';

import { ILIAS_ROUTES, ILIAS_ROUTES_NAMES } from '@/core/common/api/ilias';
import {
  GetStockAvailabilityRequest,
  StockAvailabilityResponse,
  TestDMSConnectionRequest,
} from '@/core/common/api/serviceHub';

import {
  LanguageChangeRequest,
  PutDealerNumberRequest,
  UserSettingsChangeRequest,
} from '../../utils';

export const updateLanguage = async ({
  axiosIntercept,
  data,
}: LanguageChangeRequest): Promise<
  UserInfoResponse | ErrorResponse | AxiosResponse<any, any>
> => {
  try {
    const response = await axiosIntercept.patch(
      ILIAS_ROUTES[ILIAS_ROUTES_NAMES.USER_INFO],
      data
    );
    return response;
  } catch (err) {
    return handleError(err);
  }
};

export const getUserSettings = async ({
  axiosIntercept,
}: AxiosRequest): Promise<
  UserSettings | ErrorResponse | AxiosResponse<any, any>
> => {
  try {
    const response = await axiosIntercept.get(
      PORTAL_ROUTES[PORTAL_ROUTES_NAMES.USER_SETTINGS]
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const TestDMSConnection = async ({
  axiosIntercept,
  accountLogin,
  requestToken,
}: TestDMSConnectionRequest): Promise<
  ErrorResponse | AxiosResponse<any, any>
> => {
  try {
    let url = `${
      PORTAL_ROUTES[PORTAL_ROUTES_NAMES.TEST_DMS_CONNECTION]
    }?accountLogin=${accountLogin}`;
    if (requestToken) {
      url += `&requestToken=${requestToken}`;
    }
    return await axiosIntercept.get(url);
  } catch (error) {
    return handleError(error);
  }
};

export const GetStockAvailability = async ({
  axiosIntercept,
  accountLogin,
  customerNumber,
  partsToCheck,
  requestToken,
}: GetStockAvailabilityRequest): Promise<
  StockAvailabilityResponse | ErrorResponse | AxiosResponse<any, any>
> => {
  try {
    let baseUrl: string = `${
      PORTAL_ROUTES[PORTAL_ROUTES_NAMES.STOCK_AVAILABILITY]
    }`;
    const queryParams = new URLSearchParams();

    queryParams.set('accountLogin', accountLogin);
    if (customerNumber) {
      queryParams.set('customerNumber', customerNumber);
    }
    if (requestToken) {
      queryParams.set('requestToken', requestToken);
    }

    const url: string = `${baseUrl}?${queryParams.toString()}`;

    const response: StockAvailabilityResponse = await axiosIntercept.post(
      url,
      partsToCheck
    );

    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const createUserSettings = async ({
  axiosIntercept,
  data,
}: UserSettingsChangeRequest): Promise<
  UserSettings | ErrorResponse | AxiosResponse<any, any>
> => {
  try {
    const response = await axiosIntercept.post(
      PORTAL_ROUTES[PORTAL_ROUTES_NAMES.USER_SETTINGS],
      data
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const updateUserSettings = async ({
  axiosIntercept,
  data,
}: UserSettingsChangeRequest): Promise<
  UserSettings | ErrorResponse | AxiosResponse<any, any>
> => {
  try {
    const response = await axiosIntercept.put(
      PORTAL_ROUTES[PORTAL_ROUTES_NAMES.USER_SETTINGS],
      data
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const putDealerNumberToUserSettings = async ({
  axiosIntercept,
  dealerNumber,
}: PutDealerNumberRequest): Promise<
  ErrorResponse | AxiosResponse<any, any>
> => {
  try {
    const response = await axiosIntercept.put(
      PORTAL_ROUTES[PORTAL_ROUTES_NAMES.DEALER_NUMBER],
      { dealerNumber }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const loginToMyAsaInfo = async ({
  axiosIntercept,
  data,
}: UserSettingsChangeRequest): Promise<
  UserSettings | ErrorResponse | AxiosResponse<any, any>
> => {
  try {
    const response = await axiosIntercept.post(
      ILIAS_ROUTES[ILIAS_ROUTES_NAMES.MY_ASA_INFO_LOGIN],
      data
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};
