import { AxiosResponse } from 'axios';

import {
  AxiosRequest,
  ErrorResponse,
  handleError,
} from '@asa/asasqt-microfrontend-components/dist/common/api/axios';

import { axiosLanguageConfig } from '@/types/axios';

import {
  GET_ALL_LANGUAGES_API,
  LANGUAGE_DATA_API,
} from '../utils/language.api';
import {
  DefaultLanguageResponse,
  GetLanguageDataRequest,
} from '../utils/language.types';

export const getAllLanguages = async ({
  axiosIntercept,
}: AxiosRequest): Promise<
  AxiosRequest | ErrorResponse | AxiosResponse<any, any>
> => {
  try {
    const response = await axiosIntercept.get(GET_ALL_LANGUAGES_API, {
      ...axiosLanguageConfig,
    });
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const getLanguageData = async ({
  version,
  endpoint,
  axiosIntercept,
}: GetLanguageDataRequest): Promise<
  DefaultLanguageResponse | ErrorResponse | AxiosResponse<any, any>
> => {
  let languageAPI = endpoint || LANGUAGE_DATA_API;
  try {
    const response = await axiosIntercept.get(
      version === 'null' ? languageAPI : `${languageAPI}?v=${version}`,
      axiosLanguageConfig
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};
