import { AxiosInstance } from 'axios';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import {
  userDetailsAtom,
  userServicesAtom,
  userSettingsApiDataAtom,
} from '@asa/asasqt-microfrontend-components/dist/common/state/shared/auth';
import { quotationInformationAtom } from '@asa/asasqt-microfrontend-components/dist/common/state/shared/quotation';
import {
  accountSettingsAtom,
  selectedCurrencyAtom,
} from '@asa/asasqt-microfrontend-components/dist/common/state/shared/settings';
import useAsyncData from '@asa/asasqt-microfrontend-components/dist/hooks/useAsync';

import {
  hasLicenseAtom,
  isAuthenticatedAtom,
  tokenAtom,
} from '@/core/auth/core/auth.recoil';
import { LogoutUser } from '@/core/auth/core/auth.service';
import { useAxiosIntercept } from '@/core/common/axios/axios.context';
import {
  appointmentRequestsToggleAtom,
  serviceAppSettingsAtom,
} from '@/core/common/containers/serviceApp/core';
import { URLRoutes } from '@/core/common/helpers/urlRoutes';
import {
  appointmentDateFilterDataAtom,
  dashboardSearchValueAtom,
} from '@/core/dashboard/core/recoil.atom';

import {
  hasLegalDocsToAcceptAtom,
  legalDocumentsAcceptanceAtom,
} from '../recoil';

const useLogout = () => {
  const axiosIntercept = useAxiosIntercept();
  const setIsAuthenticated = useSetRecoilState(isAuthenticatedAtom);
  const resetAuth = useResetRecoilState(isAuthenticatedAtom);
  const resetUserServices = useResetRecoilState(userServicesAtom);
  const resetTokenAtom = useResetRecoilState(tokenAtom);
  const resetLicense = useResetRecoilState(hasLicenseAtom);
  const setUserDetails = useResetRecoilState(userDetailsAtom);
  const resetAccountSettings = useResetRecoilState(accountSettingsAtom);
  const resetUserSettings = useResetRecoilState(userSettingsApiDataAtom);
  const resetAppointmentFilter = useResetRecoilState(
    appointmentDateFilterDataAtom
  );
  const resetHasLegalDocsToAccept = useResetRecoilState(
    hasLegalDocsToAcceptAtom
  );
  const resetSelectedCurrency = useResetRecoilState(selectedCurrencyAtom);
  const resetLegalDocumentsAcceptance = useResetRecoilState(
    legalDocumentsAcceptanceAtom
  );
  const resetQuotationInformation = useResetRecoilState(
    quotationInformationAtom
  );
  const resetDashboardSearchValue = useResetRecoilState(
    dashboardSearchValueAtom
  );
  const resetServiceAppSettings = useResetRecoilState(serviceAppSettingsAtom);
  const resetAppointmentRequestToggle = useResetRecoilState(
    appointmentRequestsToggleAtom
  );

  const router = useRouter();
  const [hasLoggedOut, setHasLoggedOut] = useState(false);

  const { isLoading, loadData, error } = useAsyncData({
    fetchFn: LogoutUser,
    paramsOfFetch: { axiosIntercept },
  });

  const initiateLogout = async (
    withQuery: boolean = false,
    keepRememberme: boolean = false
  ) => {
    try {
      await loadData({
        keepRememberme,
      });
      await completeLogout(withQuery);
    } catch (err) {
      await completeLogout(withQuery);
    }
  };

  const initiateLogoutWithAxiosInstance = async (
    axiosIntercept: AxiosInstance,
    keepRememberme: boolean = false,
    withQuery: boolean = false
  ) => {
    try {
      await loadData({
        axiosIntercept,
        keepRememberme,
      });
      await completeLogout(withQuery);
    } catch (err) {
      await completeLogout(withQuery);
    }
  };

  const frontendLogout = () => {
    resetUserServices();
    resetAuth();
    setIsAuthenticated(false);
    resetTokenAtom();
    resetLicense();
    setUserDetails();
    resetAccountSettings();
    resetUserSettings();
    resetLegalDocumentsAcceptance();
    resetHasLegalDocsToAccept();
    resetSelectedCurrency();
    resetQuotationInformation();
    resetDashboardSearchValue();
    resetAppointmentFilter();
    resetAppointmentRequestToggle();
    resetServiceAppSettings();
    setHasLoggedOut(true);
  };

  const completeLogout = async (withQuery: boolean = false) => {
    try {
      frontendLogout();

      await router.push({
        pathname: `/${URLRoutes.auth}`,
        query: withQuery ? router.query : {},
      });
    } catch (err) {}
  };

  return {
    initiateLogout,
    initiateLogoutWithAxiosInstance,
    isLoading,
    hasLoggedOut,
    error,
  };
};

export default useLogout;
