import { SPLIT_VALUE } from './language.const';

export const formatLanguageData = (response: string): Map<string, string> => {
  const languageMap = new Map<string, string>();
  if (!response) return languageMap;
  const split = response.split(/\r?\n/);
  split.forEach((i) => {
    const splitIndex = i.indexOf(SPLIT_VALUE);
    if (splitIndex !== -1) {
      const key = i.slice(0, splitIndex);
      const value = i.slice(splitIndex + 1);
      languageMap.set(key, value);
    }
  });
  return languageMap;
};
