import { atom } from 'recoil';

import { persistAtomOnSessionStorage } from '@asa/asasqt-microfrontend-components/dist/common/state/recoilPersist';

import {
  AcceptanceResponse,
  Documents,
} from '@/core/common/types/legalDocuments.types';

export const hasLegalDocsToAcceptAtom = atom({
  key: 'hasLegalDocsToAccept',
  default: null as boolean | null,
  effects_UNSTABLE: [persistAtomOnSessionStorage],
});

export const legalDocumentsAtom = atom({
  key: 'legalDocuments',
  default: null as Documents | null,
  effects_UNSTABLE: [persistAtomOnSessionStorage],
});

export const legalDocumentsAcceptanceAtom = atom({
  key: 'legalDocumentsAcceptances',
  default: null as AcceptanceResponse | null,
  effects_UNSTABLE: [persistAtomOnSessionStorage],
});
