import { atom, selector } from 'recoil';

import { persistAtomOnLocalStorage } from '@asa/asasqt-microfrontend-components/dist/common/state/recoilPersist';
import { isComboxConnectionSelector } from '@asa/asasqt-microfrontend-components/dist/common/state/shared';
import { quotationInformationAtom } from '@asa/asasqt-microfrontend-components/dist/common/state/shared/quotation';

import { appointmentFilterAtomDefault } from '../utils/consts';
import {
  AppointmentDateFilterData,
  DmsSearchByParamsWithDefault,
  DmsSearchByValues,
} from '../utils/types';

export const dashboardSearchValueAtom = atom<string>({
  key: 'dashboardSearchValue',
  default: '',
});

export const searchForVehiclesAtom = atom<boolean>({
  key: 'searchForVehicles',
  default: false,
});

export const shouldDisplaySearchesAtom = atom<boolean>({
  key: 'shouldDisplaySearches',
  default: false,
});

export const accountSettingsLoadingAtom = atom<boolean>({
  key: 'accountSettingsLoading',
  default: true,
});

export const dmsSearchByParamsWithDefaultAtom =
  atom<DmsSearchByParamsWithDefault | null>({
    key: 'dmsSearchByParams',
    default: {
      defaultSelectedOnDropdown: DmsSearchByValues.CustomerName,
    },
    effects_UNSTABLE: [persistAtomOnLocalStorage],
  });

export const isDmsSwitchOnAtom = atom<boolean>({
  key: 'isDmsSwitchOn',
  default: false,
  effects_UNSTABLE: [persistAtomOnLocalStorage],
});

export const appointmentDateFilterDataAtom =
  atom<AppointmentDateFilterData | null>({
    key: 'appointmentDateFilter',
    default: appointmentFilterAtomDefault,
  });

export const isDmsActiveSelector = selector({
  key: 'isDmsActive',
  get: ({ get }) => {
    const quotationInformation = get(quotationInformationAtom);
    const isDmsSwitchOn = get(isDmsSwitchOnAtom);
    const isComboxConnection = get(isComboxConnectionSelector);

    const isVinFromDms = quotationInformation?.isVinFromDms ?? false;

    return isVinFromDms || (isComboxConnection && isDmsSwitchOn);
  },
});
