import { MYASAINFO_PORTAL, MYASA_INFO, MYASA_SQT } from './auth.api';

export const parseToken = (jwToken: string) => {
  if (!jwToken) return;
  if (!jwToken?.split) return;
  return jwToken && JSON.parse(atob(jwToken.split('.')[1]));
};

export const GoToForgotPasswordHelper = (language: string) => {
  const newLocation = document.location.origin.replace(MYASA_SQT, MYASA_INFO);
  let URL = `${newLocation}${MYASAINFO_PORTAL}/#/${language}/password-forgotten`;
  process.env.NODE_ENV === 'development' &&
    (URL = `${process.env.NEXT_PUBLIC_MYASA_APP_URL}${MYASAINFO_PORTAL}/#/${language}/password-forgotten`);
  window.open(URL, '_ blank');
};
