import { useRouter } from 'next/router';
import { FC, memo } from 'react';
import React from 'react';
import { useRecoilState } from 'recoil';

import { Flex } from '@chakra-ui/layout';

import { matomoConfig } from '@asa/asasqt-microfrontend-components/dist/common/matomo/config';
import { MatomoProvider } from '@asa/asasqt-microfrontend-components/dist/common/matomo/matomo.context';
import { ToastProvider } from '@asa/asasqt-microfrontend-components/dist/layout/animate/Toast';
import { View } from '@asa/asasqt-microfrontend-components/dist/layout/ui/View';

import { AuthProvider } from '@/core/auth/core/auth.context';
import {
  hasLicenseAtom,
  isAuthenticatedAtom,
} from '@/core/auth/core/auth.recoil';
import { AxiosContextProvider } from '@/core/common/axios/axios.context';
import { LanguageContextProvider } from '@/core/common/language';
import { AccountMenu, MainMenu } from '@/core/common/layout';
import { AccountMenuMobile } from '@/core/common/layout/AccountMenuMobile';
import { hasLegalDocsToAcceptAtom } from '@/core/common/recoil';
import msYaheiFontPath from '@/theme/fonts/MSYahei.ttf';
import notoThaiFontPath from '@/theme/fonts/NotoSansThai-Regular.ttf';
import robotoFontPath from '@/theme/fonts/Roboto-Regular.ttf';

export const Providers: FC<React.PropsWithChildren<unknown>> = memo(
  ({ children }) => {
    const [isAuthenticated] = useRecoilState(isAuthenticatedAtom);
    const [hasLicense] = useRecoilState(hasLicenseAtom);
    const [hasLegalDocsToAccept] = useRecoilState(hasLegalDocsToAcceptAtom);
    const router = useRouter();

    const { siteIds, excludedQueryParams } = matomoConfig.sqt;

    if (!router.isReady) {
      return null;
    }

    const fontFacesStyle = `
    @font-face {
      font-family: 'Roboto';
      src: url('${robotoFontPath}') format('truetype');
      font-weight: 400;
      font-style: normal;
    }
    @font-face {
      font-family: 'Noto';
      src: url('${notoThaiFontPath}') format('truetype');
      font-weight: 400;
      font-style: normal;
    }
    @font-face {
      font-family: 'MSYahei';
      src: url('${msYaheiFontPath}') format('truetype');
      font-weight: 400;
      font-style: normal;
    }
  `;

    return (
      <React.Fragment>
        <style jsx global>
          {fontFacesStyle}
        </style>
        <AxiosContextProvider>
          <LanguageContextProvider>
            <MatomoProvider
              siteIds={siteIds}
              excludedQueryParams={excludedQueryParams}
            >
              <AuthProvider>
                <ToastProvider>
                  {hasLicense ? (
                    <View
                      isAuthenticated={isAuthenticated}
                      hasLegalDocsToAccept={hasLegalDocsToAccept}
                      topBarComponents={
                        <React.Fragment>
                          <MainMenu
                            m="4"
                            display={{ base: 'none', md: 'flex' }}
                          />
                          <AccountMenu />
                        </React.Fragment>
                      }
                      topBarComponentsMobile={
                        <Flex flexDirection="column" height="100%">
                          <MainMenu isDrawer />
                          <AccountMenuMobile />
                        </Flex>
                      }
                    >
                      {children}
                    </View>
                  ) : (
                    children
                  )}
                </ToastProvider>
              </AuthProvider>
            </MatomoProvider>
          </LanguageContextProvider>
        </AxiosContextProvider>
      </React.Fragment>
    );
  }
);
