import { useContext, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { AxiosStatusCode } from '@asa/asasqt-microfrontend-components/dist/common/api/axios';
import useAsyncData from '@asa/asasqt-microfrontend-components/dist/hooks/useAsync';

import {
  isAuthenticatedAtom,
  loginButtonIsLoadingAtom,
  tokenAtom,
} from '@/core/auth/core/auth.recoil';
import { getAuthorization } from '@/core/auth/core/auth.service';
import { useAxiosIntercept } from '@/core/common/axios/axios.context';

import { ALL_USER_24_SERVICES } from '../api/ilias';
import { LanguageContext } from '../language';

export const useAuthorization = () => {
  const axiosIntercept = useAxiosIntercept();
  const { tr } = useContext(LanguageContext);

  const setButtonIsLoading = useSetRecoilState(loginButtonIsLoadingAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setIsAuthenticated = useSetRecoilState(isAuthenticatedAtom);

  const [showToastError, setShowToastError] = useState<boolean>(false);
  const [toastErrorDescription, setToastErrorDescription] =
    useState<string>('');

  const {
    data: authorizationData,
    dataCode: authorizationDataCode,
    error: authorizationError,
    loadData: loadAuthorizationData,
  } = useAsyncData({
    fetchFn: getAuthorization,
    paramsOfFetch: {
      user24Services: ALL_USER_24_SERVICES,
      axiosIntercept,
    },
  });

  useEffect(() => {
    let specificErr = '';
    if (authorizationDataCode === AxiosStatusCode.Code_200) {
      setToken(authorizationData?.token);
      setIsAuthenticated(true);
      setShowToastError(false);
      setButtonIsLoading(false);
    } else if (authorizationError && authorizationDataCode) {
      switch (authorizationDataCode) {
        case AxiosStatusCode.ERR_500:
          specificErr = tr('common.error.server');
          break;
        case AxiosStatusCode.ERR_401:
          specificErr = tr('common.error.invalidLoginData');
          break;
      }

      setToastErrorDescription(tr('common.error.loginError') + specificErr);
      setShowToastError(true);
      setButtonIsLoading(false);
    }
  }, [authorizationData, authorizationError, authorizationDataCode]);

  return {
    showToastError,
    toastErrorDescription,
    loadAuthorizationData,
  };
};
