import {
  ConnectionTypeValues,
  PriceSettingsValues,
} from '@asa/asasqt-microfrontend-components/dist/common/types/shared/auth';

import { SettingsSelectOption } from './settings.types';

export const myAsaInfoLandingPage = 'accountAdmin'; // bug in myASAinfo; should be changed after fix
export const myAsaInfoContactPage = 'contact';
export const myAsaInfoDownloadsPage = 'downloads';
export const asaPriceLandingPage = 'upload';

export const priceSettingsOptions = (
  tr: any,
  isDmsDisabled: boolean = true
): SettingsSelectOption[] => {
  return [
    {
      label: tr('common.label.prices.online'),
      value: PriceSettingsValues.OnlinePrices,
    },
    {
      label: tr('common.label.prices.dealer'),
      value: PriceSettingsValues.DealerPrices,
    },
    {
      label: tr('common.label.prices.no'),
      value: PriceSettingsValues.NoPrices,
    },
    {
      label: tr('common.label.dms.prices'),
      value: PriceSettingsValues.DMSPrices,
      isDisabled: isDmsDisabled,
    },
  ];
};

export type PriceTranslation = {
  [key in PriceSettingsValues]: string;
};

export type DMSTranslation = {
  [key in ConnectionTypeValues]: string;
};

export const priceTranslation = (): PriceTranslation => {
  return {
    [PriceSettingsValues.NoPrices]: 'common.label.prices.no',
    [PriceSettingsValues.OnlinePrices]: 'common.label.prices.online',
    [PriceSettingsValues.DealerPrices]: 'common.label.prices.dealer',
    [PriceSettingsValues.DMSPrices]: 'common.label.dms.prices',
  };
};

export const dmsTranslation = (): DMSTranslation => {
  return {
    [ConnectionTypeValues.NoDmsConnection]: 'common.label.connection.no',
    [ConnectionTypeValues.Combox]: 'common.label.connection.combox',
  };
};
