import React from 'react';

import { LanguageProps } from '../utils/language.types';
import { useLanguage } from './language.hook';

export const defaultLanguageContext: LanguageProps = {
  language: undefined,
  setLanguage: () => {},
  changeLanguage: () => {},
  languageTags: new Map<string, string>(),
  tr: () => '',
};

export const LanguageContext = React.createContext<LanguageProps>(
  defaultLanguageContext
);

interface LanguageContextProviderProps {
  value?: LanguageProps;
  children: React.ReactNode;
}

export const LanguageContextProvider: React.FC<
  LanguageContextProviderProps
> = ({ children, value }) => {
  const languageProps = useLanguage();

  return (
    <LanguageContext.Provider value={value || languageProps}>
      {children}
    </LanguageContext.Provider>
  );
};
