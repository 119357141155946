import { extendTheme } from '@chakra-ui/react';

import { colorStyles } from '@asa/asasqt-microfrontend-components/dist/theme/foundations/colorStyles.js';
import { textStyles } from '@asa/asasqt-microfrontend-components/dist/theme/foundations/textStyles';

import * as components from './components';
import { config } from './config';
import foundations from './foundations';
import { styles } from './styles';

export default extendTheme({
  config,
  styles,
  ...foundations,
  textStyles,
  colorStyles,
  components: { ...components },
});
