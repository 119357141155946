const myAsainfoDomain = 'myasainfo.com';
const asasqtDomain = 'myasasqt.com';
const allowedSubdomains = ['www', 'dev', 'qs', 'demo'];

const renameDomainForCurrentUrl = (
  url: string,
  newDomain: string,
  endCharacter = '/'
): string => {
  const hostname = new URL(url).hostname;
  if (hostname === 'localhost') {
    return '';
  }
  const subdomain = hostname.split('.').slice(0, -2).join('.');
  const validSubdomain = allowedSubdomains.includes(subdomain)
    ? subdomain
    : 'www';
  return `https://${validSubdomain}.${newDomain}${endCharacter}`;
};

export const getMyAsaInfoUrl = (): string => {
  if (typeof window !== 'undefined') {
    const currentUrl = window.location.origin;
    return renameDomainForCurrentUrl(currentUrl, myAsainfoDomain);
  }

  return '';
};

export const getMyAsaInfoUrlAlt = (): string => {
  if (typeof window !== 'undefined') {
    const currentUrl = window.location.origin;
    return renameDomainForCurrentUrl(currentUrl, myAsainfoDomain, '');
  }

  return '';
};
