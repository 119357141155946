import { WeekDays } from '@asa/asasqt-microfrontend-components/dist/layout/animate/Calendar/types';

import {
  AppointmentTypesAtomForm,
  EmailNotificationType,
  SchedulingType,
} from './types';

export const initialEmailNotifications: EmailNotificationType = {
  receiveEmailNotifications: false,
  email: null,
};

export const initialAppointmentTypesForm: AppointmentTypesAtomForm = {
  hasInspectionAppointments: false,
  hasRepairAppointments: false,
  hasCustomAppointments: false,
  customAppointments: [],
};

export const initialSchedulingForm: SchedulingType = {
  appointmentInterval: null,
  openingHours: {},
};

export const MAX_CUSTOM_APPOINTMENT_NO = 3;
export const MAX_OPENING_HOURS_NO = 3;

export const DEFAULT_FROM_TIME = '08:00';
export const DEFAULT_UNTIL_TIME = '17:00';

export const HOURS_00 = 0;
export const HOURS_24 = 24;

export const weekDaysOrdered = [
  WeekDays.MONDAY,
  WeekDays.TUESDAY,
  WeekDays.WEDNESDAY,
  WeekDays.THURSDAY,
  WeekDays.FRIDAY,
  WeekDays.SATURDAY,
  WeekDays.SUNDAY,
];

export const WeekDayTranslations = {
  MONDAY: 'common.label.monday',
  TUESDAY: 'common.label.tuesday',
  WEDNESDAY: 'common.label.wednesday',
  THURSDAY: 'common.label.thursday',
  FRIDAY: 'common.label.friday',
  SATURDAY: 'common.label.saturday',
  SUNDAY: 'common.label.sunday',
};

export const InteractionModeTranslations = {
  create: 'common.label.action.create',
  edit: 'common.label.action.edit',
};
