import {
  AppointmentDateFilterData,
  AppointmentDatePeriod,
  AppointmentFilterData,
} from './types';

export const getCurrentWeekRange = (date: Date = new Date()): [Date, Date] => {
  const dayOfWeek = date.getDay();
  const today = new Date();

  const startOfWeek = new Date(today);
  startOfWeek.setHours(0, 0, 0, 0);

  const endOfWeek = new Date(date);
  endOfWeek.setDate(date.getDate() + (7 - dayOfWeek));
  endOfWeek.setHours(23, 59, 59, 999);

  return [startOfWeek, endOfWeek];
};

export const getTodayRange = (): [Date, Date] => {
  const today = new Date();

  const startOfDay = new Date(today);
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(today);
  endOfDay.setHours(23, 59, 59, 999);

  return [startOfDay, endOfDay];
};

export const getCustomRange = (
  from?: Date | string,
  until?: Date | string
): [Date, Date] | undefined => {
  if (!from || !until) {
    return;
  }

  const startDate = new Date(from);
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date(until);
  endDate.setHours(23, 59, 59, 999);

  if (!isValidDate(startDate) || !isValidDate(endDate)) {
    return;
  }

  return [startDate, endDate];
};

const isValidDate = (date?: Date) => {
  return date instanceof Date && !isNaN(date.getTime());
};

export const APPOINTMENT_DATE_BE_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_PAGE_SIZE = 10;

export const appointmentFilterDataDefaultValue: AppointmentFilterData = {
  allFilters: false,
  today: false,
  thisWeek: false,
  custom: false,
};

export const appointmentFilterAtomDefault: AppointmentDateFilterData = {
  ...appointmentFilterDataDefaultValue,
  period: AppointmentDatePeriod.All,
  from: null,
  until: null,
  dates: undefined,
};
