import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { useInterval } from '@chakra-ui/react';

import { AxiosStatusCode } from '@asa/asasqt-microfrontend-components/dist/common/api/axios';
import useAsyncData from '@asa/asasqt-microfrontend-components/dist/hooks/useAsync';

import {
  isAuthenticatedAtom,
  shouldUseRememberMe,
  tokenAtom,
} from '@/core/auth/core/auth.recoil';
import { getRememberMe } from '@/core/auth/core/auth.service';

import { useAxiosIntercept } from '../axios/axios.context';
import { useAuthorization } from './useAuthorization';

export const useAuthentication = () => {
  const axiosIntercept = useAxiosIntercept();
  const { loadAuthorizationData } = useAuthorization();
  const [token] = useRecoilState(tokenAtom);

  const [isAuthenticated, setIsAuthenticated] =
    useRecoilState(isAuthenticatedAtom);
  const [useRememberMe] = useRecoilState(shouldUseRememberMe);

  const [runExpiryTimer, setExpiryTimer] = useState<number | null>();

  const { dataCode: getRememberMeDataCode, loadData: loadRememberMeData } =
    useAsyncData({
      fetchFn: getRememberMe,
      paramsOfFetch: {
        axiosIntercept,
      },
    });

  const getTokenExpirationTimestamp = (token: string): number | null => {
    const jwt = JSON.parse(atob(token.split('.')[1]));
    return (jwt && jwt.exp && jwt.exp * 1000) || null;
  };

  const setExpiryTimerFromToken = (token: string) => {
    const expirationTimestamp = getTokenExpirationTimestamp(token);
    if (expirationTimestamp) {
      const now = new Date().getTime();
      const diff = expirationTimestamp - now;

      if (diff > 0) {
        setExpiryTimer(diff - 1000 * 60 * 2);
      }
    }
  };

  const shouldRefreshToken = (token: string): boolean => {
    const expirationTimestamp = getTokenExpirationTimestamp(token);
    return Date.now() > (expirationTimestamp || 0);
  };

  useInterval(
    () => {
      loadAuthorizationData();
    },
    runExpiryTimer ? runExpiryTimer : null
  );

  useEffect(() => {
    if (token) {
      if (shouldRefreshToken(token)) {
        loadAuthorizationData();
      } else {
        setIsAuthenticated(true);
        setExpiryTimerFromToken(token);
      }
    } else {
      if (useRememberMe) {
        loadRememberMeData();
      }
    }
  }, [token]);

  useEffect(() => {
    if (getRememberMeDataCode === AxiosStatusCode.Code_200) {
      loadAuthorizationData();
    }
  }, [getRememberMeDataCode]);

  useEffect(() => {
    !isAuthenticated && setExpiryTimer(null);
  }, [isAuthenticated]);

  return { isAuthenticated, setIsAuthenticated };
};
