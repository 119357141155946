import { AxiosResponse } from 'axios';

import {
  AxiosRequest,
  ErrorResponse,
  handleError,
} from '@asa/asasqt-microfrontend-components/dist/common/api/axios';
import {
  CUSTOMER_ROUTES,
  CUSTOMER_ROUTES_NAMES,
  NOTIFICATION_ROUTES,
  NOTIFICATION_ROUTES_NAMES,
  QUOTATION_ROUTES,
  QUOTATION_ROUTES_NAMES,
  VHC_ROUTES,
  VHC_ROUTE_NAMES,
} from '@asa/asasqt-microfrontend-components/dist/common/api/myAsaSqt';
import {
  GetNotificationListRequest,
  GetNotificationListResponse,
  UpdateNotificationStatusRequest,
  UpdateNotificationStatusResponse,
} from '@asa/asasqt-microfrontend-components/dist/common/types/notifications';
import {
  GetQuotationCommentsRequest,
  QuotationCommentsResponse,
  QuotationInfoByExternalIdResponse,
} from '@asa/asasqt-microfrontend-components/dist/common/types/quotation';
import { VhcInfoByExternalIdResponse } from '@asa/asasqt-microfrontend-components/dist/common/types/vhc';

import { getMyAsaInfoUrlAlt } from '@/core/common/helpers/myasainfo/url';

export type QuotationInfoType = AxiosRequest & {
  externalId: string;
};

export type VhcInfoType = AxiosRequest & {
  externalId: string;
};

export type VehicleInfoType = AxiosRequest & {
  vehicleExternalId: string;
};

const myAsaInfoUrl = getMyAsaInfoUrlAlt();

export const GetNotifications = async ({
  axiosIntercept,
  page,
  size,
  searchText,
  sortOption,
  types,
  statuses,
  isRead,
}: GetNotificationListRequest): Promise<
  GetNotificationListResponse | ErrorResponse | AxiosResponse<any, any>
> => {
  try {
    const params = new URLSearchParams({ applicationName: 'ASASQT' });

    if (page >= 0) {
      params.append('page', page.toString());
    }

    params.append('size', size.toString());

    if (searchText) {
      params.append('searchText', searchText);
    }

    if (sortOption) {
      params.append('sortBy', `${sortOption.colName},${sortOption.direction}`);
    }

    if (types && types.length > 0) {
      params.append('types', types.toString());
    }

    if (statuses && statuses.length > 0) {
      params.append('statuses', statuses.toString());
    }

    if (isRead !== null) {
      params.append('isRead', isRead);
    }

    const url = `${myAsaInfoUrl}${
      NOTIFICATION_ROUTES[NOTIFICATION_ROUTES_NAMES.NOTIFICATIONS]
    }?${params.toString()}`;

    const response: GetNotificationListResponse = await axiosIntercept.get(url);
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const UpdateNotificationStatus = async ({
  axiosIntercept,
  notificationId,
  isRead,
}: UpdateNotificationStatusRequest): Promise<
  UpdateNotificationStatusResponse | ErrorResponse | AxiosResponse<any, any>
> => {
  try {
    const params = new URLSearchParams();
    params.append('isRead', isRead.toString());

    const url = `${myAsaInfoUrl}${NOTIFICATION_ROUTES[
      NOTIFICATION_ROUTES_NAMES.NOTIFICATION_STATUS
    ].replace(
      '{notificationId}',
      notificationId.toString()
    )}?${params.toString()}`;
    const response = await axiosIntercept.put(url);

    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const UnreadNotificationCount = async ({
  axiosIntercept,
}: AxiosRequest): Promise<
  number | ErrorResponse | AxiosResponse<number, any>
> => {
  try {
    const params = new URLSearchParams({ applicationName: 'ASASQT' });

    const url = `${myAsaInfoUrl}${
      NOTIFICATION_ROUTES[NOTIFICATION_ROUTES_NAMES.NOTIFICATIONS]
    }/unread/count?${params.toString()}`;
    const response = await axiosIntercept.get(url);

    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const GetQuotationInfoByExternalId = async ({
  axiosIntercept,
  externalId,
}: QuotationInfoType): Promise<
  | QuotationInfoByExternalIdResponse
  | ErrorResponse
  | AxiosResponse<QuotationInfoByExternalIdResponse, any>
> => {
  try {
    let url = `${QUOTATION_ROUTES[
      QUOTATION_ROUTES_NAMES.GET_QUOTATION_INFO_BY_EXTERNAL_ID
    ].replace('{externalId}', externalId)}`;
    const response: QuotationInfoByExternalIdResponse =
      await axiosIntercept.get(url);
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const GetVhcInfoByExternalId = async ({
  axiosIntercept,
  externalId,
}: VhcInfoType): Promise<
  | VhcInfoByExternalIdResponse
  | ErrorResponse
  | AxiosResponse<VhcInfoByExternalIdResponse, any>
> => {
  try {
    let url = `${VHC_ROUTES[VHC_ROUTE_NAMES.VHC_INFO_BY_EXTERNAL_ID].replace(
      '{externalId}',
      externalId
    )}`;
    const response: VhcInfoByExternalIdResponse = await axiosIntercept.get(url);
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const GetVehicleInfoByExternalId = async ({
  axiosIntercept,
  vehicleExternalId,
}: VehicleInfoType): Promise<
  | VhcInfoByExternalIdResponse
  | ErrorResponse
  | AxiosResponse<VhcInfoByExternalIdResponse, any>
> => {
  try {
    let url = `${CUSTOMER_ROUTES[
      CUSTOMER_ROUTES_NAMES.VEHICLE_INFO_BY_EXTERNAL_ID
    ].replace('{vehicleExternalId}', vehicleExternalId)}`;
    const response: VhcInfoByExternalIdResponse = await axiosIntercept.get(url);
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const GetComments = async ({
  axiosIntercept,
  quotationId,
}: GetQuotationCommentsRequest): Promise<
  QuotationCommentsResponse | ErrorResponse | AxiosResponse<any, any>
> => {
  try {
    const url = QUOTATION_ROUTES[QUOTATION_ROUTES_NAMES.COMMENTS].replace(
      '{quotationId}',
      quotationId.toString()
    );
    const response: QuotationCommentsResponse = await axiosIntercept.get(url);

    return response;
  } catch (error) {
    return handleError(error);
  }
};
