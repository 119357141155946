import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { userDetailsAtom } from '@asa/asasqt-microfrontend-components/dist/common/state/shared';
import { UserDetails } from '@asa/asasqt-microfrontend-components/dist/common/types/shared/auth';
import useAsyncData from '@asa/asasqt-microfrontend-components/dist/hooks/useAsync';

import { isAuthenticatedAtom } from '@/core/auth/core/auth.recoil';
import { useAxiosIntercept } from '@/core/common/axios/axios.context';
import {
  getAllLanguages,
  getLanguageData,
} from '@/core/common/language/core/language.service';

import fallbackTags from '../resources/messages_en.fallback.json';
import { formatLanguageData } from '../utils/formatLangData';
import {
  GetAllLanguagesResponse,
  LanguageDetailsType,
} from '../utils/language.types';
import { languageAtom } from './language.recoil';

export const useLanguage = () => {
  const [isAuthenticated] = useRecoilState(isAuthenticatedAtom);
  const [language, setLanguage] = useRecoilState(languageAtom);
  const [userDetails] = useRecoilState<UserDetails | null>(userDetailsAtom);
  const [languageLabel, setLanguageLabel] = useState<string>('English');
  const [languageTags, setLanguageTags] = useState<Map<string, string>>(
    new Map<string, string>()
  );
  const [languageDetails, setLanguageDetails] = useState<LanguageDetailsType>();
  const axiosIntercept = useAxiosIntercept();

  const { data: translationsInfo, loadData: loadTranslationsInfo } =
    useAsyncData({
      fetchFn: getAllLanguages,
      paramsOfFetch: { axiosIntercept },
    });

  const { data: languageData, loadData: loadLanguageData } = useAsyncData({
    fetchFn: getLanguageData,
    paramsOfFetch: {
      version: languageDetails?.version,
      endpoint: languageDetails?.endpoint,
      axiosIntercept,
    },
  });

  const tr = (tag: string): string => {
    if (languageTags.has(tag)) return languageTags.get(tag)!;
    return fallbackTags[tag] || tag;
  };

  const handleAllLanguagesData = (response: GetAllLanguagesResponse) => {
    if (!response) return;
    const {
      version,
      bestMatchingLocale,
      languages,
      bestMatchingPropertiesBundle,
    } = response;

    const selectedLanguage = languages.find(
      (lang) => lang.locale === bestMatchingLocale
    );

    setLanguageDetails((prevState) => ({
      ...prevState,
      version: version || 'null',
      endpoint:
        selectedLanguage?.propertiesBundle || bestMatchingPropertiesBundle,
    }));

    selectedLanguage && setLanguageLabel(selectedLanguage?.text);
    selectedLanguage && setLanguage(selectedLanguage?.locale);
  };

  //Todo test and re-enable for 2.4
  // const handleTranslationsLocally = async () => {
  //   const languageCode = language === 'in' ? 'id' : language || 'en';
  //   const jsonData = await import(
  //     `../resources/messages_${languageCode}.properties.json`
  //   );
  //   const formattedData: Map<string, string> = new Map(
  //     Object.entries(jsonData.default)
  //   );

  //   if (!mapsAreEqual(languageTags, formattedData)) {
  //     setLanguageTags(formattedData);
  //   }
  // };

  useEffect(() => {
    if (translationsInfo) {
      handleAllLanguagesData({
        ...translationsInfo,
        bestMatchingLocale:
          userDetails?.language || translationsInfo.bestMatchingLocale,
      });
    }
  }, [translationsInfo, userDetails?.language]);

  useEffect(() => {
    languageData && setLanguageTags(formatLanguageData(languageData));
  }, [languageData]);

  useEffect(() => {
    languageDetails && languageDetails.version && loadLanguageData();
  }, [languageDetails]);

  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  useEffect(() => {
    isAuthenticated && userDetails && loadTranslationsInfo();
  }, [isAuthenticated, userDetails]);

  useEffect(() => {
    !isAuthenticated && loadTranslationsInfo();
  }, [isAuthenticated]);

  return { language, languageLabel, languageTags, setLanguage, tr };
};
