import { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';

import { getAuthorization } from '@/core/auth/core/auth.service';

import { ALL_USER_24_SERVICES } from '../api/ilias';
import { useAxiosIntercept } from '../axios/axios.context';
import { tokenAtom } from '../recoil';

function useRefreshToken() {
  const axiosIntercept = useAxiosIntercept();
  const [token, setToken] = useRecoilState(tokenAtom);

  const [isTokenRefreshed, setTokenRefreshed] = useState(false);

  const refreshToken = useCallback(async () => {
    const authorization = await getAuthorization({
      axiosIntercept,
      user24Services: ALL_USER_24_SERVICES,
    });

    if ('data' in authorization && authorization.data?.token) {
      setToken(authorization.data?.token);
      setTokenRefreshed(true);
      return authorization.data?.token;
    } else {
      return null;
    }
  }, []);

  return {
    token,
    isTokenRefreshed,
    refreshToken,
    setTokenRefreshed,
  };
}

export default useRefreshToken;
