export enum DocumentSeverity {
  BLOCKING = 'BLOCKING',
  INFORMAL = 'DocumentSeverity',
}

export type DocumentItem = {
  url: string;
  mediaType?: string;
  severity?: string;
};

export enum LegalDocType {
  AsaPrivacy = 'asa-privacy',
  AsaTos = 'asa-tos',
  AsaSqtDataProcessing = 'asasqt-dataprocessing',
  AsaLegalNotice = 'asa-legalnotice',
}

export type Documents = {
  [LegalDocType.AsaPrivacy]: DocumentItem;
  [LegalDocType.AsaTos]: DocumentItem;
  [LegalDocType.AsaSqtDataProcessing]: DocumentItem;
  [LegalDocType.AsaLegalNotice]: DocumentItem;
};

export interface DocumentResponse {
  documents: Documents | null;
}

export interface DocumentAcceptance {
  documentName: string;
  accepted: boolean;
  version: number;
}

export type DocumentsAcceptance = {
  [LegalDocType.AsaPrivacy]: DocumentAcceptance;
  [LegalDocType.AsaTos]: DocumentAcceptance;
  [LegalDocType.AsaSqtDataProcessing]: DocumentAcceptance;
};

export interface AcceptanceResponse {
  acceptances: DocumentsAcceptance | null;
  allAcceptedOnce: boolean;
}

export interface DocumentsAcceptanceResponse {
  documents: Documents | null;
  legalDocAcceptance: AcceptanceResponse;
}
