export const ILIAS_ROUTES_NAMES = {
  LOGIN: 'ILIAS_LOGIN',
  REMEMBER_ME: 'ILIAS_REMEMBER_ME',
  AUTHORIZATION: 'ILIAS_AUTHORIZATION',
  PUB_DOCUMENTS: 'ILIAS_DOCUMENTS',
  URLS_ACCEPTANCE_AFTER_LOGIN: 'URLS_ACCEPTANCE_AFTER_LOGIN',
  ACCEPTANCE_AFTER_LOGIN: 'ACCEPTANCE_AFTER_LOGIN',
  USER_INFO: 'ILIAS_USER_INFO',
  MY_ASA_INFO_LOGIN: 'MY_ASA_INFO_LOGIN',
};

export const ILIAS_ROUTES: { [key: string]: string } = {
  [ILIAS_ROUTES_NAMES.LOGIN]: '/asailias/ext/api/auth/session',
  [ILIAS_ROUTES_NAMES.REMEMBER_ME]: '/asailias/ext/api/auth/session/rememberme',
  [ILIAS_ROUTES_NAMES.AUTHORIZATION]: '/asailias/ext/api/auth/authorization',
  [ILIAS_ROUTES_NAMES.PUB_DOCUMENTS]: '/asailias/ext/pub/api/legaldoc/info',
  [ILIAS_ROUTES_NAMES.USER_INFO]: '/asailias/ext/api/userinfo',
  [ILIAS_ROUTES_NAMES.URLS_ACCEPTANCE_AFTER_LOGIN]:
    '/asailias/ext/api/legaldoc/info/latest',
  [ILIAS_ROUTES_NAMES.ACCEPTANCE_AFTER_LOGIN]:
    '/asailias/ext/api/legaldoc/acceptance/latest',
  [ILIAS_ROUTES_NAMES.MY_ASA_INFO_LOGIN]: '/asailias/ext/api/session/sender',
};

export const PUBLIC_DOCUMENTS_TYPE = [
  'asa-privacy',
  'asa-tos',
  'asasqt-dataprocessing',
  'asa-legalnotice',
];

export const computeLegalDocumentsQuery = () => {
  let legalDocumentQuery = '';

  for (let legalDocument in PUBLIC_DOCUMENTS_TYPE) {
    legalDocumentQuery += `legalDocumentName=${PUBLIC_DOCUMENTS_TYPE[legalDocument]}&`;
  }

  return legalDocumentQuery.slice(0, -1);
};
