import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { AxiosStatusCode } from '@asa/asasqt-microfrontend-components/dist/common/api/axios';
import useAsyncData from '@asa/asasqt-microfrontend-components/dist/hooks/useAsync';

import { useAxiosIntercept } from '@/core/common/axios/axios.context';
import { unreadNotificationCountAtom } from '@/core/common/recoil/notification.recoil.atom';
import { UnreadNotificationCount } from '@/core/notifications/core/service';

export const useUnreadNotificationCount = () => {
  const axiosIntercept = useAxiosIntercept();
  const [unreadCount, setUnreadCount] = useRecoilState(
    unreadNotificationCountAtom
  );
  const [unreadCountShort, setUnreadCountShort] = useState<string>('');

  const {
    data: notificationsCountData,
    dataCode: notificationsCountDataCode,
    isLoading: notificationsCountIsLoading,
    loadData: fetchCount,
  } = useAsyncData({
    fetchFn: UnreadNotificationCount,
    paramsOfFetch: {
      axiosIntercept,
    },
  });

  useEffect(() => {
    if (notificationsCountDataCode !== AxiosStatusCode.Code_200) {
      return;
    }

    setUnreadCount(notificationsCountData);
  }, [notificationsCountData, notificationsCountDataCode]);

  useEffect(() => {
    setUnreadCountShort(unreadCount > 99 ? '99+' : unreadCount.toString());
  }, [unreadCount]);

  return {
    unreadCount,
    unreadCountShort,
    notificationsCountIsLoading,
    fetchCount,
  };
};
