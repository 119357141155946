import { AxiosRequest } from '@asa/asasqt-microfrontend-components/dist/common/api/axios/utils/types';
import { UserRole } from '@asa/asasqt-microfrontend-components/dist/common/types/shared/api/userServices';
import { PriceSettingsValues } from '@asa/asasqt-microfrontend-components/dist/common/types/shared/auth';
import { SelectOption } from '@asa/asasqt-microfrontend-components/dist/form/FieldSelect';

import {
  OperationApplicability,
  PartApplicability,
  PartCategory,
} from '@/core/common/api/serviceHub';

export type SectionButton = {
  icon: TODO;
  label: string;
  requiredRoles?: UserRole[];
  requireAllRoles?: boolean;
  href?: string;
};

export type SettingsSectionProps = {
  title: string;
  requiredRoles: UserRole[];
  sectionButtons: SectionButton[];
};

export type SettingsLanguage = {};

export interface UserSettingsChangeRequest extends AxiosRequest {
  data: Language;
}
export interface LanguageChangeRequest extends AxiosRequest {
  data: Language;
}

export interface PutDealerNumberRequest extends AxiosRequest {
  dealerNumber: string;
}

export type Language = {
  language: string;
};

export type LanguageOption = {
  label: string;
  value: string;
};

export type SettingsSelectOption = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

export type SettingsSelectGroupedOption = {
  label: string;
  options: SettingsSelectOption[];
};

export type PriceSettingsAtomProps = {
  options: SettingsSelectOption[];
  defaultValue?: string;
  selectedOption?: string;
  showImportButton: boolean;
  onPriceChange: (value: PriceSettingsValues) => void;
  onClickImportPrices: () => void;
};

export type LanguageElement = {
  locale: string;
  propertiesBundle: string;
  text: string;
};

export type PriceSettingsSectionProps = {
  onChange: (value: PriceSettingsValues) => void;
  showImportButton: boolean;
  language?: string;
};

export enum RedirectLoginStrategy {
  HtmlForm = 'HTMLFORM',
  LoginToken = 'LOGINTOKEN',
}

export enum RedirectLandingPages {
  AsaIlias = 'ASAILIAS',
  MyAsaInfoPortal = 'MYASAINFO_PORTAL',
  MyAsaInfoTranslate = 'MYASAINFO_TRANSLATE',
  MyAsaInfoPriceUpload = 'MYASAINFO_PRICEUPLOAD',
  MyAsaInfoMediaCenter = 'MYASAINFO_MEDIACENTER',
  AsaSqt = 'ASASQT',
  AsaSqtPortal = 'ASASQT_PORTAL',
  AsaDesktop = 'ASADESKTOP',
  AsaWeb = 'ASAWEB',
}

export interface HourlyRateType {
  key: string;
  type: string;
  hourlyRate: number | null;
  id: number | string;
  isUsedInCustomOperations?: boolean;
  isDefault: boolean;
}

export interface HourlyRatesForm {
  hourlyRates: HourlyRateType[];
  inspectionLabourTypeLabel: string;
  repairLabourTypeLabel: string;
  inspectionTaxRateType: SelectOption;
  repairTaxRateType: SelectOption;
}

export enum QuotationValidityType {
  Days = 'DAYS',
  Weeks = 'WEEKS',
  MonthLastDay = 'MONTH_LAST_DAY',
}

export interface GeneralDetailsFormValue {
  addPartIn: PartApplicability;
  category: PartCategory;
  description: string;
  partNumber: string;
  isPublish?: boolean;
}

export interface AccountPriceAndQuantityFormValue {
  packagePrice: number;
  taxRateId: number;
  packageQuantity: number;
}

export interface CountryPriceAndQuantityFormValue {
  packagePrice: number;
  packageQuantity: number;
}

export interface CountryPartsFilterBy {
  isPublished: boolean | null;
  accountId: string | null;
}

export interface OperationGeneralDetailsFormValue {
  addOperationIn: OperationApplicability;
  description: string;
  code: string;
  isPublished: boolean;
}
