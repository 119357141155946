import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { userServicesAtom } from '@asa/asasqt-microfrontend-components/dist/common/state/shared/auth';
import { UserRole } from '@asa/asasqt-microfrontend-components/dist/common/types/shared/api/userServices';

import { hasLicenseAtom } from '@/core/auth/core/auth.recoil';
import { parseToken } from '@/core/auth/utils/auth.helper';
import { Market, UserServices } from '@/core/common/api/ilias';

import { URLRoutes } from '../helpers';
import { tokenAtom } from '../recoil';

export const useUserServices = () => {
  const [token] = useRecoilState(tokenAtom);
  const router = useRouter();
  const [userServices, setUserServices] = useRecoilState(userServicesAtom);

  const [hasLicense, setHasLicense] = useRecoilState(hasLicenseAtom);

  const marketsToCheck = [
    Market.qsm50,
    Market.qsm60,
    Market.qsm70,
    Market.qsm80,
  ];

  useEffect(() => {
    if (!token) return;
    const jwt = parseToken(token);
    const jwtScopes: (UserRole | Market)[] = jwt?.scope.split(' ') || [];

    if (!jwtScopes) {
      return;
    }

    if (jwtScopes.length === 1) {
      setHasLicense(false);
    }

    const rolesServices = jwtScopes.reduce<UserServices>(
      (accumulator, scope) => {
        if (Object.values(UserRole).includes(scope as UserRole)) {
          return {
            ...accumulator,
            roles: [...accumulator.roles, scope as UserRole],
          };
        } else if (Object.values(Market).includes(scope as Market)) {
          return {
            ...accumulator,
            markets: [...accumulator.markets, scope as Market],
          };
        } else {
          return accumulator;
        }
      },
      { roles: [], markets: [] }
    );

    const hasMarkets = rolesServices.markets.some((market) =>
      marketsToCheck.includes(market as Market)
    );

    setHasLicense(hasMarkets);
    setUserServices(rolesServices);
  }, [token, setUserServices]);

  useEffect(() => {
    if (hasLicense === false) {
      router.push(`/${URLRoutes.unauthorized}`).then(() => {
        () => window.location.reload();
      });
    }
  }, [hasLicense]);

  return { userServices, setUserServices };
};
